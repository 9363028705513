@tailwind base;
@tailwind components;
@tailwind utilities;


/*  scrollbar */
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #a8a8a8;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: #242424;
}